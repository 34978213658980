// eslint-disable-next-line @typescript-eslint/no-var-requires
const env = require('env-var').from(process.env);

export interface Config {
  nodeEnv: string;
  akamaiSdkUrl: string;
  apiChannel: string;
  apiKey: string;
  appMiddlewareHost: string;
  appMiddlewareGqlEndpoint: string;
  appMiddlewareMobileLoginEndpoint: string;
  urlDataProtection: string;
  urlCookie: string;
  urlPrivacy: string;
  urlTCS: string;
  urlFindPwd: string;
  /**
   * @deprecated see README for more details
   */
  appMiddlewareSendSmsSecuritySwitch: boolean;
  /**
   * @deprecated see README for more details
   */
  appMiddlewareSendSmsHost: string;
  /**
   * @deprecated see README for more details
   */
  appMiddlewareSendSmsGqlEndpoint: string;
  appMiddlewareSendSmsEndpoint: string;
  geetestCaptchaIdForLogin: string;
  geetestCaptchaIdForSms: string;
  geetestLanguage: string;
  geetestVersion: string;
}

export const config: Config = {
  // GLOBAL
  nodeEnv: env.get('NODE_ENV').default('development').asString(),
  akamaiSdkUrl: env.get('AKAMAI_SDK_URL').default('').asString(),

  // APP-MIDDLEWARE
  apiChannel: env.get('API_CHANNEL').default('mini-program').asString(),
  apiKey: env.get('API_KEY').default('').asString(),
  appMiddlewareHost: env.get('APP_MIDDLEWARE_HOST').default('http://localhost:3000').asString(),
  appMiddlewareGqlEndpoint: env.get('APP_MIDDLEWARE_GQL_ENDPOINT').default('/graphql').asString(),
  appMiddlewareMobileLoginEndpoint: env
    .get('APP_MIDDLEWARE_MOBILE_LOGIN_ENDPOINT')
    .default('/api/guests/login')
    .asString(),

  // URLs
  urlDataProtection: env
    .get('URL_DATA_PROTECTION')
    .default('https://wcprd.hilton.com.cn/mp/pages/data-protection-clause.html')
    .asString(),
  urlCookie: env.get('URL_COOKIE').default('https://wcprd.hilton.com.cn/mp/pages/cookies-statement.html').asString(),
  urlPrivacy: env.get('URL_PRIVACY').default('https://wcprd.hilton.com.cn/mp/pages/global-privacy.html').asString(),
  urlTCS: env.get('URL_TCS').default('https://wcprd.hilton.com.cn/mp/pages/terms.html').asString(),
  urlFindPwd: env
    .get('URL_FIND_PWD')
    .default('https://www.hilton.com/zh-hans/hilton-honors/login/forgot-information/')
    .asString(),

  appMiddlewareSendSmsSecuritySwitch: env.get('APP_MIDDLEWARE_SEND_SMS_SECURITY_SWITCH_ON').default('true').asBool(),
  appMiddlewareSendSmsHost: env.get('APP_MIDDLEWARE_SEND_SMS_HOST').default('http://localhost:3000').asString(),
  appMiddlewareSendSmsGqlEndpoint: env.get('APP_MIDDLEWARE_SEND_SMS_GQL_ENDPOINT').default('/graphql').asString(),
  appMiddlewareSendSmsEndpoint: env.get('APP_MIDDLEWARE_SEND_SMS_ENDPOINT').default('/api/guests/sendSms').asString(),
  geetestCaptchaIdForLogin: env
    .get('GEETEST_CAPTCHA_ID_FOR_LOGIN')
    .default('13bab310d67804f663bac53fc90306cf')
    .asString(),
  geetestCaptchaIdForSms: env.get('GEETEST_CAPTCHA_ID_FOR_SMS').default('13bab310d67804f663bac53fc90306cf').asString(),
  geetestLanguage: env.get('GEETEST_LANGUAGE').default('zho').asString(),
  geetestVersion: env.get('GEETEST_VERSION').default('4').asString(),
};
